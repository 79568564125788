import { Button } from "@material-ui/core";
import PrintIcon from "@mui/icons-material/Print";
import * as XLSX from "xlsx";
import { mapForExport } from "./mapForExport";

interface ExportXLSButtonProps {
	columns: string[];
	rows: any[];
	totals: {
		payout: number;
		payoutMTD: number;
		target: number;
		quantity: number;
	};
}

export const ExportXLSButton = ({
	columns,
	rows,
	totals,
}: ExportXLSButtonProps) => {
	const exportToXLSX = () => {
		const wb = XLSX.utils.book_new();

		const filteredColumns = columns.filter(column => column !== "Accounts" && column !== "Products");

		const totalRow = [
			"Total",
			...filteredColumns.slice(1).map((column) => {
				if (column === "Potential") return totals.payout;
				if (column === "Payout MTD") return totals.payoutMTD;
				if (column === "Target") return totals.target;
				if (column === "Quantity") return totals.quantity;
				return "";
			}),
		];

		const transformedRows = rows.map((row) =>
			mapForExport(row, filteredColumns)
		);

		const dataWithTotal = [filteredColumns, ...transformedRows, totalRow];

		const ws = XLSX.utils.aoa_to_sheet(dataWithTotal);
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "exported_data.xlsx");
	};

	return (
		<Button
			size="small"
			variant="outlined"
			color="primary"
			startIcon={<PrintIcon />}
			onClick={exportToXLSX}
		>
			Print
		</Button>
	);
};
