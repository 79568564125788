import { Button } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import exportCsv from "../../../utils/exportCsv";
import { formatImagesDataToRows, mapImagesDataForExport } from "./utils";


interface ExportImagesCSVProps {
    rows: any[];
}

export const ExportImagesCSV = ({rows}: ExportImagesCSVProps) => {

    const imagesData = mapImagesDataForExport(rows);
    const csvRows = formatImagesDataToRows(imagesData);

    const handleExport = () => {
        exportCsv("Images", csvRows);
    };

    return (
        <Button
            size="small"
            variant="outlined"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={handleExport}
        >
            Export Images CSV
        </Button>
    )
}
