import { columnKeyMap, formatDate } from "./utils";

export const mapForExport = (
    row: any,
    columns: string[]
): string[] => {
    const orderedRow: string[] = [];
    columns.forEach((column) => {
        const key = columnKeyMap[column];

        switch (column) {
            case "Start Date":
            case "End Date":
                orderedRow.push(formatDate(row[key] || ""));
                break;
            case "Status":
                if (
                    row.type === "image" ||
                    row.type === "binary" ||
                    row.type === "quantity"
                ) {
                    const progress = row.status || row.progress;
                    if (progress === "∞") {
                        orderedRow.push(progress);
                    } else if (!isNaN(Number(progress))) {
                        const progressNum = Number(progress);
                        orderedRow.push(`${progressNum}`);
                    } else {
                        orderedRow.push("0");
                    }
                } else {
                    orderedRow.push("Not defined");
                }
                break;
            case "Image":
                if (row.image && row.image.length > 0) {
                    const acceptedImages = row.image.filter((image: { rejected: boolean }) => !image.rejected);

                    const imageDetails = acceptedImages.map((image: { imageUrl: string; notes: string }) => {
                        if (image.notes) {
                            return `${image.imageUrl} (${image.notes})`;
                        }
                        return image.imageUrl;
                    });
                    orderedRow.push(imageDetails.join("; "));
                } else {
                    orderedRow.push("-");
                }
                break;
            case "Rejected Images":
                if (row.rejectedImages && row.rejectedImages.length > 0) {
                    const rejectedImages = row.rejectedImages.filter((image: { rejected: boolean }) => image.rejected);

                    const imageDetails = rejectedImages.map((image: { imageUrl: string; rejectedReason: string }) => {
                        if (image.rejectedReason) {
                            return `${image.imageUrl} (${image.rejectedReason})`;
                        }
                        return image.imageUrl;
                    });
                    orderedRow.push(imageDetails.join("; "));
                } else {
                    orderedRow.push("-");
                }
                break;
            case "Input Value Type":
                const valueTypeMap: Record<string, string> = {
                    gr: "Greater than",
                    lt: "Less than",
                    gre: "Greater than or equal",
                    lte: "Less than or equal",
                };
                orderedRow.push(valueTypeMap[row[key]] || "-");
                break;
            case "Quantity":
            case "Minimum Quantity":
                orderedRow.push(row[key]?.toString() || "0");
                break;
            case "Payout MTD":
                orderedRow.push(row[key] ? `${row[key]}` : "0");
                break;
            case "Potential":
                orderedRow.push(
                    row[key] ? (row[key] !== "∞" ? `${row[key]}` : "∞") : "0"
                );
                break;
            case "Route":
                orderedRow.push(row[key] || "-");
                break;
            default:
                orderedRow.push(row[key]?.toString() || "N/A");
                break;
        }
    });

    return orderedRow;
};
