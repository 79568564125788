import { Button } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import { mapForExport } from "./mapForExport";
import exportCsv from "../../../utils/exportCsv";

interface ExportCSVButtonProps {
	columns: string[];
	rows: any[];
	totals: {
		payout: number;
		payoutMTD: number;
		target: number;
		quantity: number;
	};
}

export const ExportCSVButton = ({
	columns,
	rows,
	totals,
}: ExportCSVButtonProps) => {

	const filteredColumns = columns.filter(column => column !== "Accounts" && column !== "Products");

	const totalRow = [
		"Total",
		...filteredColumns.slice(1).map((column) => {
			if (column === "Potential") return totals.payout;
			if (column === "Payout MTD") return totals.payoutMTD;
			if (column === "Target") return totals.target;
			if (column === "Quantity") return totals.quantity;
			return "";
		}),
	];
	const transformedRows = rows.map((row) =>
		mapForExport(row, filteredColumns)
	);

	const handleExport = () => {
		exportCsv("Reporting Table", [filteredColumns, ...transformedRows, totalRow]);
	};

	return (
		<Button
			size="small"
			variant="outlined"
			color="primary"
			startIcon={<DownloadIcon />}
			onClick={handleExport}
		>
			Export CSV
		</Button>
	);
};
