import { Button } from "@material-ui/core";
import PrintIcon from "@mui/icons-material/Print";
import * as XLSX from "xlsx";
import { mapImagesDataForExport, formatImagesDataToRows } from "./utils";

interface ExportImagesXLSXProps {
    rows: any[];
}

export const ExportImagesXLSX = ({ rows }: ExportImagesXLSXProps) => {
    const imagesData = mapImagesDataForExport(rows);
    const xlsxData = formatImagesDataToRows(imagesData);

    const handleExportXLSX = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(xlsxData);
        XLSX.utils.book_append_sheet(wb, ws, "Images");

        XLSX.writeFile(wb, "images_data.xlsx");
    };

    return (
        <Button
            size="small"
            variant="outlined"
            color="primary"
            startIcon={<PrintIcon />}
            onClick={handleExportXLSX}
        >
            Export Images XLSX
        </Button>
    );
};
